.language-config-component .flags-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.language-config-component .flags-container>*:not(:last-child) {
  margin-right: 20px;
}

.language-config-component .flags-container .flag {
  width: 40px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.language-config-component .flags-container .flag:hover {
  transform: scale(1.1);
}

.language-config-component .flags-container .flag.selected {
  border: 2px solid #ffffff;
}