.layout-component {
  background-image: url(./../../../assets/img/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 30px;
}

.layout-component .header {
  color: #C89B3C;
  border-bottom: 1px solid #C89B3C;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.layout-component .top-right-block {
  position: absolute;
  top: 4vh;
  right: 2vw;
  display: flex;
}

.layout-component .top-right-block > *:not(:last-child) {
  margin-right: 10px;
}

.layout-component .content {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.layout-component .content > * {
  justify-self: center;
  max-height: 100%;
  overflow: hidden;
}