.random-champion-component>*:not(:last-child) {
    margin-bottom: 10px;
}

.random-champion-component .team-container .champion-selectors .random-champion {
    opacity: 0;
    transition: all 1s;
}

.random-champion-component .team-container .champion-selectors .random-champion.show {
    opacity: 1;
}