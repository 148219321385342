/* Forms */
input {
    padding-left: 5px;
    padding-right: 5px;
}

input,
.input-group-text,
.form-select,
.accordion-button {
    height: 5vh !important;
    color: #ffffff !important;
    background-color: #3C3C41 !important;
    border: 1px solid #5B5A56 !important;
}

input:focus,
.form-select:focus {
    box-shadow: #5B5A56 0px 1px 4px !important;
}

input::placeholder,
.form-select.empty-select:not(option) {
    color: #5B5A56 !important;
}

input,
.form-select {
    border-radius: 4px !important;
}

.form-select {
    padding-top: 0;
    padding-right: 40px;
}

.form-select {
    --bs-form-select-bg-img: url('./../assets/icons/arrow.svg') !important;
}

.form-select option {
    color: #ffffff !important;
}

.form-check-input {
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
}

.form-switch .form-check-input {
    width: 40px !important;
}

/* Accordion */
.accordion .accordion-item {
    border: 0;
}

.accordion .accordion-item .accordion-header .accordion-button,
.accordion .accordion-item .accordion-header .accordion-button.collapsed {
    --bs-accordion-btn-icon: url('./../assets/icons/arrow.svg');
    box-shadow: #5B5A56 0px 1px 4px !important;
}

.accordion .accordion-item .accordion-header .accordion-button::after {
    background-image: url('./../assets/icons/arrow.svg');
}

.accordion .accordion-item .accordion-body {
    padding: 10px !important;
    color: #ffffff;
    background-color: #3C3C41;
}

/* Modal */
.modal-body {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* Nav tabs */
.nav.nav-tabs {
    flex: 1;
}

.nav.nav-tabs .nav-item .nav-link {
    color: #ffffff;
    background-color: #5B5A56;
    border: none;
    border-bottom: 1px solid white;
}

.nav.nav-tabs .nav-item .nav-link.active {
    background-color: #3C3C41;
}

.tab-content {
    overflow: auto;
}

.tab-content .tab-pane {
    padding: 10px;
}