.summary-component {
  display: flex;
  flex-direction: column;
}

.summary-component > *:not(:last-child) {
  margin-bottom: 10px;
}

.summary-component .action-container {
  overflow: auto;
}

.summary-component .options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.summary-component .buttons-container {
  margin-top: 10px;
}