img[alt~='roulette-static'] {
  position: absolute;
  z-index: 5;
  width: 65px;
  right: 30px;
  top: 30px;
  content: url('./../../../../../assets/img/pointer.png');
}

.lucky-wheel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roulette-center {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}