* {
  text-align: center;
  font-family: 'BeaufortforLOL-Medium.ttf';
  font-size: calc(10px + 1.5vmin) !important;
}

a {
  color: #3C3C41 !important;
}

.default-message {
  color: #ffffff;
  text-shadow: #5B5A56 0px 1px 4px !important;
}

/* General */
.custom-border {
  width: 25vw;
  background-size: 10px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  border-top: 10px solid #C89B3C;
  border-bottom: 10px solid #785A28;
  background-image:
    linear-gradient(#C89B3C, #785A28),
    linear-gradient(#C89B3C, #785A28);
  background-color: #0A323C;
  color: #ffffff;
  padding: 15px 25px;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  padding: 0 1rem;
}