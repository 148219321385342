::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #3C3C41;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb {
    background-color: #5B5A56;
    border-radius: 50px;
    border: 2px solid #3C3C41;
}