.custom-icon-component {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: all 0.2s;
}

.custom-icon-component:hover {
  transform: scale(1.1);
}

/* Sizes */
.custom-icon-component.big {
  width: 80px;
  height: 80px;
}

.custom-icon-component.medium {
  height: 25px;
  width: 25px;
}

.custom-icon-component.small {
  height: 15px;
  width: 15px;
}

/* Types */
.custom-icon-component.config {
  background-image: url('./../../../assets/icons/settings.svg');
}

.custom-icon-component.info {
  background-image: url('./../../../assets/icons/info.svg');
}

.custom-icon-component.edit {
  background-image: url('./../../../assets/icons/edit.svg');
}

.custom-icon-component.delete {
  background-image: url('./../../../assets/icons/trash.svg');
}

.custom-icon-component.warning {
  background-image: url('./../../../assets/icons/warning.svg');
}

.custom-icon-component.roulette-center {
  background-image: url('./../../../assets/img/logo.png');
}