.pick-enemy-team-champions-component .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
}

.pick-enemy-team-champions-component .form .team-container>*:not(:last-child) {
    margin-bottom: 5px;
}

.pick-enemy-team-champions-component .form .team-container .champion-selectors>*:not(:last-child) {
    margin-bottom: 10px;
}