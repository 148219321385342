/* Font sizes */
.big-font-size {
    font-size: calc(15px + 1.5vmin) !important;
}

.medium-font-size {
    font-size: calc(8px + 1.5vmin) !important;
}

.small-font-size {
    font-size: calc(2px + 1.5vmin) !important;
}

/* Font family */
@font-face {
    font-family: 'BeaufortforLOL-Regular.ttf';
    font-style: normal;
    font-weight: normal;
    src: local('./../assets/fonts/BeaufortforLOL-Regular.ttf'), url('./../assets/fonts/BeaufortforLOL-Regular.woff') format('woff');
}

@font-face {
    font-family: 'BeaufortforLOL-Medium.ttf';
    font-style: normal;
    font-weight: normal;
    src: local('./../assets/fonts/BeaufortforLOL-Medium.ttf'), url('./../assets/fonts/BeaufortforLOL-Medium.woff') format('woff');
}