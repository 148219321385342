.game-component {
  position: relative;
  overflow: visible !important;
}

.game-component .reset-wheel-options-button {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
}

#required-teams-modal .modal-body > *:not(:last-child) {
  margin-bottom: 10px;
}