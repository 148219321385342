.team-randomizer-component {
  display: flex;
}

.team-randomizer-component .carousel {
  width: 100%;
}

.team-randomizer-component .carousel .carousel-inner,
.team-randomizer-component .carousel .carousel-inner .carousel-item,
.team-randomizer-component .carousel .carousel-inner .carousel-item .team-randomizer-form {
  height: 100%;
}

.team-randomizer-component .carousel .carousel-inner .carousel-item .team-randomizer-form {
  display: flex;
  flex-direction: column;
}

.team-randomizer-component .team-randomizer-form > *:not(:last-child) {
  margin-bottom: 20px;
}

.team-randomizer-component .team-randomizer-form .players-container {
  overflow: auto;
  flex: 1;
}

.team-randomizer-component .team-randomizer-form .players-container .player-container {
  display: flex;
}

.team-randomizer-component .team-randomizer-form .players-container .player-container>*:not(:last-child) {
  margin-right: 10px;
}

.team-randomizer-component .team-randomizer-form .players-container .player-container .player-main-input {
  width: 10vw;
}

.team-randomizer-component .team-randomizer-form .players-container .player-container:not(:last-child) {
  margin-bottom: 10px;
}

.team-randomizer-component .results-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  margin-bottom: 10px;
}