.roulette-config-component .roulette-options-form {
  margin-top: 10px;
}

.roulette-config-component .roulette-options-form > *:not(:last-child) {
  margin-bottom: 20px;
}

.roulette-config-component .roulette-options-form .accordion-header-without-content button::after {
  display: none;
}

.roulette-config-component .roulette-options-form .roulette-option {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 5px;
}

.roulette-config-component .roulette-options-form .roulette-option > *:not(:last-child) {
  margin-right: 10px;
}

.roulette-config-component .roulette-options-form .roulette-option .header-title {
  flex-grow: 1;
  text-align: left;
  display: flex;
  align-items: center;
}

.roulette-config-component .roulette-options-form .roulette-option .header-title > *:not(:first-child) {
  margin-left: 10px;
}

.roulette-config-component .roulette-options-form .roulette-options {
  padding-left: 45px;
}

.roulette-config-component .roulette-options-form .new-wheel-option-container.opened {
  border: 2px solid #5B5A56;
  border-radius: 4px;
  padding: 10px;
}

.roulette-config-component .roulette-options-form .new-wheel-option-container.opened > *:not(:last-child) {
  margin-bottom: 10px;
}

.roulette-config-component .roulette-options-form .new-wheel-option-container .new-wheel-option-suboptions {
  padding: 0 10px 0 25px;
}

.roulette-config-component .roulette-options-form .new-wheel-option-container .new-wheel-option-suboptions .new-wheel-option-subOption {
  display: flex;
  align-items: center;
}

.roulette-config-component .roulette-options-form .new-wheel-option-container .new-wheel-option-suboptions .new-wheel-option-subOption > *:not(:first-child) {
  margin-left: 10px;
}