.custom-toaster-component .toast {
  cursor: pointer;
  border: none;
  color: #C89B3C;
  background-color: #ffffff;
  width: fit-content;
  border-radius: 4px;
  transform: translateY(100%);
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(-10px);
    opacity: 1;
  }
}

@keyframes slideOutDown {
  from {
    transform: translateY(-10px);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.toast.slide-in {
  animation: slideInUp 1s ease-out forwards;
}

.toast.slide-out {
  animation: slideOutDown 1s ease-in forwards;
}